import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { withI18n } from '@lingui/react';

function Nav({ onMenuToggle = () => {} }) {
  const language =
    typeof window !== `undefined` &&
    JSON.parse(window.localStorage.getItem('translate'));

  typeof window !== `undefined` &&
    window.addEventListener('scroll', () => {
      const header = document.querySelector('#desktop');
      header.classList.toggle('sticky', window.scrollY > 0);
      header.classList.toggle('no-sticky', window.scrollY === 0);
    });

  return (
    <div style={{ position: 'relative', zIndex: '10' }}>
      <Menu id="nav">
        <ul id="ul">
          <li className="special">
            <a
              href="#menu"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              className="menuToggle"
            >
              <span>Menu</span>
            </a>
            <div id="menu">
              <ul>
                <li>
                  <Link to={!language ? '/es' : '/'}>
                    {!language ? 'Inicio' : 'Home'}
                  </Link>
                </li>
                <li>
                  <Link to={!language ? '/es/#four' : '/#four'}>
                    {!language ? 'Contacto' : 'Contact'}
                  </Link>
                </li>
              </ul>

              <a
                className="close"
                onClick={e => {
                  e.preventDefault();
                  onMenuToggle();
                }}
                href="#menu"
              >
                {''}
              </a>
            </div>
          </li>
        </ul>
        <ul id="desktop">
          <li>
            <Link to={!language ? '/es' : '/'}>
              {!language ? 'Inicio' : 'Home'}
            </Link>
          </li>
          <li>
            <Link to={!language ? '/es/#four' : '/#four'}>
              {!language ? 'Contacto' : 'Contact'}
            </Link>
          </li>
        </ul>
      </Menu>
    </div>
  );
}

export default withI18n()(Nav);

const Menu = styled.nav`
  @media (min-width: 993px) {
    width: 100%;
    #ul {
      display: none;
    }
  }

  #desktop {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .sticky {
    animation: animateMenu 1s ease-in-out;
    background: #38aeee;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .no-sticky {
    animation: animateNoScroll 0.5s ease-in-out;
  }

  @keyframes animateMenu {
    0% {
      background: transparent;
    }
    100% {
      background: #38aeee;
    }
  }

  @keyframes animateNoScroll {
    0% {
      background: #38aeee;
    }
    100% {
      background: transparent;
    }
  }
`;
