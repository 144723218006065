import '../assets/sass/main.scss';
import '../assets/css/nucleo-icons.css';
import '../assets/scss/blk-design-system-pro-react.scss?v1.0.0';
import '../assets/demo/demo.css';
import '../assets/demo/react-demo.css';

import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import BackgroundImg from 'gatsby-background-image';
import Footer from './Footer';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import SideBar from './Sidebar';
import { createGlobalStyle } from 'styled-components';

//importar los scss del template aquí

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
  @media (max-width: 1024px) {
    .content.aos-init {
    transform: translate(0) !important
  }
  }
`;

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            banner: file(name: { eq: "banner" }) {
              name
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'StrokeSoft' },
                { name: 'keywords', content: 'site, web' },
              ]}
            >
              <html lang="en" />
              {/* Global site tag (gtag.js) - Google Analytics */}
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-129055289-1"
              ></script>
              <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-129055289-1');
              `}
              </script>
              <script>
                {`
                !(function(p,r,i,v,a,c,y){p['MetomicObject']=a;p[a]=p[a]||function(){
                (p[a].q=p[a].q||[]).push(arguments)},p[a].l=1*new Date();c=r.createElement(i),
                y=r.getElementsByTagName(i)[0];c.async=1;c.src=v+'?d='+r.location.host;y.parentNode.insertBefore(c,y)
                })(window, document, 'script', 'https://consent-manager.metomic.io/embed.js', 'Metomic');
                Metomic('load', { projectId: 'prj:6032bd82-24ad-456b-a7b2-b5af275e0775' });
              `}
              </script>
            </Helmet>
            <GlobalStyle />
            <div
              className={
                isPreloaded
                  ? 'landing main-body is-preload'
                  : 'landing main-body'
              }
            >
              <div id="page-wrapper">
                {/* to remove the lag when scrolling we need to look how the 
                browser renders the fixed backdrounds, learn more about this https://www.fourkitchens.com/blog/article/fix-scrolling-performance-css-will-change-property/. 

                In a nutshell
                when using a fixed background the brower repaints the image 
                whenever there's a movement 
                
                In order to fix these we need the replicate the behavior of 
                background fixed and using the will-change css property to prevent repainting
                Using the gatsby-background-image we can replicate this behaviour along with all 
                the coll features of this plugin like lazy loading*/}
                <BackgroundImg
                  critical={true}
                  fluid={[
                    'linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
                    data.banner.childImageSharp.fluid,
                  ]}
                  style={{
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    content: '',
                    position: 'fixed',
                    willChange: 'tranform',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: -1,
                  }}
                />
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
