import React, { useState } from 'react';

import { Link } from 'gatsby';
import Nav from './Nav';
import styled from 'styled-components';

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);

  return (
    <Header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <h1>
        <Link to="/">StrokeSoft</Link>
      </h1>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </Header>
  );
}

const Header = styled.header`
  z-index: 100 !important;
`;
