import React from 'react';

export default function Footer() {
  const redirecTo = e => {
    e.preventDefault();
    window.location.replace(`javascript:window.Metomic('ConsentManager:show')`);
  };
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; Spectral</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>
        </li>
      </ul>
      <a className="cookies" href="#!" onClick={redirecTo}>
        Gestionar cookies
      </a>
    </footer>
  );
}
